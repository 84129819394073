import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserPage from "./pages/UserPage";
import Login from "./pages/LoginPage";

import { useEffect, useState } from "react";
import Register from "./pages/register/Register";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import ForgotPassword from "./components/auth/ForgotPassword";
function App() {
  const [isLogin, setIsLogin] = useState(false);
  const ROLES = {
    User: "user",
    Editor: "editor",
    Admin: "admin",
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLogin(true);
    }
  });

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/login" element={<Login onLogin={undefined} />} />
          <Route exact path="/reset-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={ROLES.User} />}>
              <Route path="/" element={<UserPage />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
