import axios from "axios";
// Pass axiosPrivate as a parameter
export const searchDictionary = async (
  searchValue,
  selectedKeyLng,
  axiosPrivate
) => {
  try {
    const response = await axiosPrivate.get(`/dictionary/`, {
      params: {
        input: searchValue,
        language: selectedKeyLng,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
export const editDictionaryRecord = async (idRecord, data) => {
  try {
    const response = await axios.put(`/dictionary/edit/${idRecord}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteDictionaryRecord = async (idRecord, axiosPrivate) => {
  try {
    const response = await axiosPrivate.delete(
      `/dictionary/delete/${idRecord}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const confirmAddData = async (data, axiosPrivate) => {
  try {
    const response = await axiosPrivate.post(`/dictionary/add`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const confirmEditData = async (idRecord, data, axiosPrivate) => {
  try {
    const response = await axiosPrivate.put(
      `/dictionary/edit/${idRecord}`,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
