import React, { FC } from "react";

export const BoldTickSquare = ({ color = "currentcolor" }) => (
  <svg width="20" height="20" viewBox="2 2 20 20" fill="none">
    <path
      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
      fill={color}
    />
  </svg>
);

export const LinearHome = ({ color = "currentcolor" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 18V15"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.07 2.82009L3.14001 8.37009C2.36001 8.99009 1.86001 10.3001 2.03001 11.2801L3.36001 19.2401C3.60001 20.6601 4.96001 21.8101 6.40001 21.8101H17.6C19.03 21.8101 20.4 20.6501 20.64 19.2401L21.97 11.2801C22.13 10.3001 21.63 8.99009 20.86 8.37009L13.93 2.83009C12.86 1.97009 11.13 1.97009 10.07 2.82009Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LinearSort = ({ color = "currentcolor" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M3 7H21" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    <path d="M6 12H18" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M10 17H14"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const OutLineInfoCircle = ({ color = "currentcolor" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
      fill={color}
    />
    <path
      d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z"
      fill={color}
    />
    <path
      d="M12 16.9999C11.87 16.9999 11.74 16.9699 11.62 16.9199C11.5 16.8699 11.39 16.7999 11.29 16.7099C11.2 16.6099 11.13 16.5099 11.08 16.3799C11.03 16.2599 11 16.1299 11 15.9999C11 15.8699 11.03 15.7399 11.08 15.6199C11.13 15.4999 11.2 15.3899 11.29 15.2899C11.39 15.1999 11.5 15.1299 11.62 15.0799C11.86 14.9799 12.14 14.9799 12.38 15.0799C12.5 15.1299 12.61 15.1999 12.71 15.2899C12.8 15.3899 12.87 15.4999 12.92 15.6199C12.97 15.7399 13 15.8699 13 15.9999C13 16.1299 12.97 16.2599 12.92 16.3799C12.87 16.5099 12.8 16.6099 12.71 16.7099C12.61 16.7999 12.5 16.8699 12.38 16.9199C12.26 16.9699 12.13 16.9999 12 16.9999Z"
      fill={color}
    />
  </svg>
);
export const OutLineWarning = ({ color = "currentcolor" }) => (
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.5 20.625V32.0833"
      stroke="#FF5656"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={color}
    />
    <path
      d="M27.5 49.0646H13.6125C5.6604 49.0646 2.33749 43.3812 6.18749 36.4375L13.3375 23.5583L20.075 11.4583C24.1542 4.10208 30.8458 4.10208 34.925 11.4583L41.6625 23.5813L48.8125 36.4604C52.6625 43.4042 49.3167 49.0875 41.3875 49.0875H27.5V49.0646Z"
      stroke="#FF5656"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.4874 38.9583H27.508"
      stroke="#FF5656"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LinearMenu = ({ color = "currentcolor" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
  >
    <path
      d="M9.66602 8H29.4994"
      stroke={color}
      strokeOpacity="0.9"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M4 17H29.5"
      stroke={color}
      strokeOpacity="0.9"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M9.66602 26H29.4994"
      stroke={color}
      strokeOpacity="0.9"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
