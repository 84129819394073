import React, { useEffect, useState } from "react";
import "./edit.scss";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../../hooks/useAuth";
import { checkboxDataDefault } from "../../data";

const EditModal = ({
  onClose,
  onSave,
  checkboxData,
  recordData,
  fullEditedData,
}) => {
  const [formData, setFormData] = useState(fullEditedData || recordData);
  const [editedData, setEditedData] = useState({});
  const { auth } = useAuth();
  const handleSave = (e) => {
    e.preventDefault();
    delete formData._highlightResult;
    onSave(formData, editedData);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newEditedData = { ...editedData };

    if (value === "") {
      delete newEditedData[name];
    } else {
      newEditedData[name] = value;
    }

    setEditedData(newEditedData);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const isSaveButtonDisabled =
    Object.keys(editedData).length === 0 &&
    Object.keys(editedData).every((key) => editedData[key] === "")
      ? true
      : false;
  return (
    <Modal
      centered
      className="edit_modal"
      closable={false}
      visible={true}
      title={
        <div
          className="edit-icon-container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </div>
      }
      footer={false}
      onCancel={onClose}
    >
      <form onSubmit={handleSave} style={{ marginTop: "16px" }}>
        {auth?.roles.includes("admin") ? (
          <>
            {checkboxDataDefault
              .filter((checkbox) => checkbox.key !== "notes")
              .map((checkbox, index) => (
                <div key={index} className="form-group">
                  <label htmlFor={checkbox.key}>{checkbox.label}</label>
                  <input
                    id="value1"
                    autoComplete="off"
                    type={checkbox.key === "Occurrences" ? "number" : "text"}
                    name={checkbox.key}
                    onChange={handleInputChange}
                    value={formData[checkbox.key]}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              ))}
            <div className="form-group">
              <label htmlFor="note">
                Ghi chú<strong> </strong>
              </label>
              <textarea
                id="value2" // Use a unique id for this textarea
                value={formData["note"]}
                name="note"
                onChange={handleInputChange}
              />
            </div>
          </>
        ) : (
          <>
            {checkboxData
              .filter((checkbox) =>
                auth?.allowedColumns?.includes(checkbox.key)
              )
              .map((checkbox, index) => (
                <div key={index} className="form-group">
                  <label htmlFor={checkbox.key}>{checkbox.label}</label>
                  <input
                    id="value1" // Use checkbox.key as the id to make it unique
                    autoComplete="off"
                    type={checkbox.key === "Occurrences" ? "number" : "text"}
                    name={checkbox.key}
                    onChange={handleInputChange}
                    value={formData[checkbox.key]}
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              ))}
            <div className="form-group">
              <label htmlFor="Occurrences">
                Số lần xuất hiện<strong> </strong>
              </label>
              <input
                id="value1"
                autoComplete="off"
                type="number"
                name="Occurrences"
                onChange={handleInputChange}
                value={formData["Occurrences"]}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <div className="form-group">
              <label htmlFor="verse">Địa chỉ</label>
              <input
                id="value1"
                autoComplete="off"
                type="text"
                name="verse"
                onChange={handleInputChange}
                value={formData["verse"]}
                onWheel={(e) => e.target.blur()}
              />
            </div>

            <div className="form-group">
              <label htmlFor="note">
                Ghi chú<strong> </strong>
              </label>
              <textarea
                id="value1" // Use a unique id for this textarea
                value={formData["note"]}
                name="note"
                onChange={handleInputChange}
              />
            </div>
          </>
        )}

        <div style={{ display: "flex" }}>
          <button className="btn btn-cancel" onClick={onClose}>
            Hủy
          </button>
          <button
            disabled={isSaveButtonDisabled}
            type="submit"
            className="btn btn-save"
          >
            Lưu
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default EditModal;
