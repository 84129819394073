import { useRef, useState, useEffect } from "react";
import "./register.scss";
import "../layout.scss";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import { message } from "antd";
import RegistrationSuccess from "./RegistrationSuccess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const REGISTER_URL = "/register";

const Register = () => {
  const userRef = useRef();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [confirmPasswordInputType, setConfirmPasswordInputType] = useState("password");

  const handleInputName = (value) => {
    setUser(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    // Check if the passwords match
    if (pwd !== confirmPwd) {
      setErrMsg("Mật khẩu xác nhận không khớp.");
      return;
    }

    try {
      setIsLoading(true);
      await axios.post(
        REGISTER_URL,
        JSON.stringify({ username: user, password: pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: false,
        }
      );
      setSuccess(true);
      setIsLoading(false);
    } catch (err) {
      if (err) {
        setIsLoading(false);
        console.log(err);
        message.error(err.response.data.error);
      }
    }
  };

  const handleConfirmPwd = (value) => {
    setConfirmPwd(value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setPasswordInputType(passwordInputType === "password" ? "text" : "password");
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
    setConfirmPasswordInputType(confirmPasswordInputType === "password" ? "text" : "password");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {isLoading && <div class="rotate-image"></div>}
        <div
          className={`bg-login-page ${isLoading && "icon_loading_container"} `}
        >
          {success ? (
            <RegistrationSuccess />
          ) : (
            <section className="register-container">
              <h1>Register</h1>
              <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="form-group">
                      {" "}
                      <label style={{ display: "block" }} htmlFor="username">
                        Email:{" "}
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          height: "50px",
                        }}
                      >
                        <input
                          type="text"
                          id="username"
                          ref={userRef}
                          autoComplete="off"
                          onChange={(e) => handleInputName(e.target.value)}
                          value={user}
                          required
                          aria-describedby="uidnote"
                        />{" "}
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="password" style={{ display: "block" }}>
                        Mật khẩu:{" "}
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          height: "50px",
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          <input
                            id="password"
                            type={passwordInputType}
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            required
                            minLength={8}
                            maxLength={12}
                          />
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye} // Eye icon
                            onClick={togglePasswordVisibility} // Toggle password visibility
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="confirmPassword"
                        style={{ display: "block" }}
                      >
                        Xác nhận mật khẩu:{" "}
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          height: "50px",
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          <input
                            type={confirmPasswordInputType}
                            id="confirmPassword"
                            onChange={(e) => handleConfirmPwd(e.target.value)}
                            value={confirmPwd}
                            required
                            minLength={8}
                            maxLength={12}
                          />
                          <FontAwesomeIcon
                            icon={showConfirmPassword ? faEyeSlash : faEye} // Eye icon
                            onClick={toggleConfirmPasswordVisibility} // Toggle confirm password visibility
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {errMsg && <p style={{ color: "red" }}>{errMsg}!</p>}
                <button
                  className="btn btn-register"
                  onClick={(e) => handleSubmit}
                >
                  Đăng ký
                </button>
              </form>
              <p>
                Bạn đã đăng ký?
                <br />
                <span className="line">
                  <Link
                    to="/login"
                    style={{ color: "#c70f15", textDecoration: "none" }}
                  >
                    Đăng Nhập
                  </Link>
                </span>
              </p>
            </section>
          )}
        </div>
      </div>
    </>
  );
};

export default Register;