export const checkboxDataDefault = [
  { id: 1, key: "English", label: "Anh", checked: true },
  { id: 2, key: "Vietnamese", label: "Việt", checked: true },
  { id: 3, key: "Chinese", label: "Trung", checked: false },
  { id: 4, key: "Hebrew", label: "Hê-bơ-rơ", checked: false },
  { id: 5, key: "Greek", label: "Hi Lạp", checked: false },
  { id: 6, key: "note", label: "Ghi chú", checked: false },
  { id: 7, key: "verse", label: "Địa chỉ", checked: false },
  { id: 8, key: "Occurrences", label: "Số lần xuất hiện", checked: true },
];
