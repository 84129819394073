import React, { useEffect } from "react";
import { Modal } from "antd";
import "./warning.scss";
import { OutLineWarning } from "../../../icons/Essetional";
const Warning = ({ message, onDelete, onClose, selectedRecord }) => {
  return (
    <>
      <Modal
        visible={true}
        onCancel={onClose}
        className="warning_modal"
        closable={false}
        footer={
          <div style={{ display: "flex", gap: "8px" }}>
            <button className="btn btn-cancel" onClick={onClose}>
              Hủy
            </button>
            <button
              className="btn btn-delete"
              onClick={() => onDelete(selectedRecord.objectID)}
            >
              Xóa
            </button>
          </div>
        }
        width={329}
        centered
      >
        <div className="warning-icon-container">
          <OutLineWarning color={"rgba(255, 86, 86, 1)"} />
        </div>
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          {message}
        </div>
      </Modal>
    </>
  );
};

export default Warning;
