import React, { useEffect, useRef, useState } from "react";
import { Modal, Input, List, message, Checkbox } from "antd";
import "./admin.scss";
import {
  authorizeEditableColumns,
  confirmAllowAddData,
  confirmAllowDeleteData,
  confirmPromoteAdmin,
  confirmPromoteEditor,
  confirmPromoteUser,
  confirmRevokeAdmin,
  confirmRevokeEditor,
  confirmRevokeUser,
} from "../../utils/api/adminApis.js";
import { all } from "axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
export const defaultCheckBoxLng = [
  { id: 1, key: "English", label: "Anh", checked: false },
  { id: 2, key: "Vietnamese", label: "Việt", checked: false },
  { id: 3, key: "Chinese", label: "Trung", checked: false },
  { id: 4, key: "Hebrew", label: "Hê-bơ-rơ", checked: false },
  { id: 5, key: "Greek", label: "Hi Lạp", checked: false },
];

const UserManagementModal = ({ visibleManageUserModal, onCancel }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [checkboxes, setCheckboxes] = useState(defaultCheckBoxLng);
  const [userRoles, setUserRoles] = useState({});
  const [isMessageShowing, setIsMessageShowing] = useState(false);
  const [userEditableColumns, setUserEditableColumns] = useState([]);
  const [visibleEditedColumnModal, setVisibleEditedColumnsModal] =
    useState(false);
  const axiosPrivate = useAxiosPrivate();

  const renderCount = useRef(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    renderCount.current += 1;

    // In số lần render vào console

    try {
      const response = await axiosPrivate.get("/admin/get-all-users");
      const userRolesData = {};
      response.data.users.forEach((user) => {
        userRolesData[user.username] = {
          isAdmin: user?.roles?.admin || false,
          isEditor: user?.roles?.editor || false,
          isUser: user?.roles?.user || false,
        };
      });
      setUserRoles(userRolesData);
      setUsers(response.data.users);

      const allowedColumns = {};
      response.data.users.forEach((user) => {
        allowedColumns[user.username] = [...checkboxes].map((column) => {
          if (user?.allowedColumns?.includes(column.key)) {
            return { ...column, checked: true };
          } else {
            return { ...column, checked: false };
          }
        });
      });
      setUserEditableColumns(allowedColumns);
    } catch (error) {}
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
    const filtered = users.filter((user) =>
      user.username.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredUsers(filtered);
  };
  const handleAdminClick = async (userEmail, isAdmin) => {
    if (!isAdmin) {
      const result = window.confirm("Xác nhận ủy quyền admin cho user này?");
      if (result) {
        try {
          await confirmPromoteAdmin(userEmail, axiosPrivate);
          message.success("Cập nhật thành công!");
          setUserRoles((prevUserRoles) => ({
            ...prevUserRoles,
            [userEmail]: {
              ...prevUserRoles[userEmail],
              isAdmin: true,
            },
          }));
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      const result = window.confirm("Xác nhận hủy vai trò admin của user này?");
      if (result) {
        try {
          await confirmRevokeAdmin(userEmail, axiosPrivate);
          message.info("Cập nhật thành công!", 3);
          setUserRoles((prevUserRoles) => ({
            ...prevUserRoles,
            [userEmail]: {
              ...prevUserRoles[userEmail],
              isAdmin: false,
            },
          }));
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const handleUserClick = async (userEmail, isUser) => {
    if (!isUser) {
      const result = window.confirm(
        `Xác nhận ủy quyền user cho ${userEmail}  này?`
      );
      if (result) {
        try {
          await confirmPromoteUser(userEmail, axiosPrivate);
          message.success("Cập nhật thành công!");
          setUserRoles((prevUserRoles) => ({
            ...prevUserRoles,
            [userEmail]: {
              ...prevUserRoles[userEmail],
              isUser: true,
            },
          }));
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      const result = window.confirm(
        `Xác nhận hủy vai trò user của ${userEmail} này?`
      );
      if (result) {
        try {
          await confirmRevokeUser(userEmail, axiosPrivate);
          message.success("Cập nhật thành công!");
          setUserRoles((prevUserRoles) => ({
            ...prevUserRoles,
            [userEmail]: {
              ...prevUserRoles[userEmail],
              isUser: false,
            },
          }));
        } catch (e) {
          console.log(e);
        }
      }
    }
  };
  const handleEditorClick = async (userEmail, isUser) => {
    if (!isUser) {
      setVisibleEditedColumnsModal(true);
    } else {
      const result = window.confirm(
        `Xác nhận hủy vai trò editor của ${userEmail} này?`
      );
      if (result) {
        try {
          await confirmRevokeEditor(userEmail, axiosPrivate);
          setUserRoles((prevUserRoles) => ({
            ...prevUserRoles,
            [userEmail]: {
              ...prevUserRoles[userEmail],
              isEditor: false,
            },
          }));
          message.success("Cập nhật thành công!");
        } catch (e) {
          console.log(e);
        }
      }
    }
  };
  const handleCheckboxPromoteEditor = async (id, username, column) => {
    const updatedCheckboxes = checkboxes.map((checkbox) => {
      if (checkbox.id === id) {
        return { ...checkbox, checked: !checkbox.checked };
      }

      return checkbox;
    });
    setCheckboxes(updatedCheckboxes);
  };
  const handleCheckboxChange = async (id, username, column, isChecked) => {
    console.log(isChecked);
    const updatedCheckboxes = checkboxes.map((checkbox) => {
      if (checkbox.id === id) {
        return { ...checkbox, checked: !checkbox.checked };
      }

      return checkbox;
    });

    try {
      const res = await authorizeEditableColumns(
        username,
        column,
        axiosPrivate,
        isChecked
      ); // Check if a message is already showing
      if (!isMessageShowing) {
        message.success(res.data.message);
        setIsMessageShowing(true);
        // Automatically hide the message after a delay (e.g., 3 seconds)
        setTimeout(() => {
          message.destroy();
          setIsMessageShowing(false);
        }, 3000);
      }
    } catch (e) {
      // Check if a message is already showing
      if (!isMessageShowing) {
        message.error(e.message);
        setIsMessageShowing(true);

        // Automatically hide the message after a delay (e.g., 3 seconds)
        setTimeout(() => {
          message.destroy();
          setIsMessageShowing(false);
        }, 3000);
      }
    }
    setCheckboxes(updatedCheckboxes);
  };
  const handAllowAddData = async (email, isRevokeAddData) => {
    try {
      const res = await confirmAllowAddData(
        email,
        isRevokeAddData,
        axiosPrivate
      );
      message.success(res.data.message);
    } catch (e) {
      message.error(e.response.data.message);
    }
  };
  const handAllowDeleteData = async (email, isRevokeDeleteData) => {
    try {
      const res = await confirmAllowDeleteData(
        email,
        isRevokeDeleteData,
        axiosPrivate
      );
      message.success(res.data.message);
    } catch (e) {
      message.error(e.response.data.message);
    }
  };

  const handleConfirmPromoteEditor = async (email, index) => {
    const checkedItems = checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.key);
    if (checkedItems.length <= 0) {
      message.error("Vui lòng chọn ít nhất một ngôn ngữ.");
    } else {
      try {
        const response = await confirmPromoteEditor(
          email,
          checkedItems,
          axiosPrivate
        );
        setUserRoles((prevUserRoles) => ({
          ...prevUserRoles,
          [email]: {
            ...prevUserRoles[email],
            isEditor: true,
          },
        }));
        const updateUserEditableColumns = { ...userEditableColumns };
        updateUserEditableColumns[email] = userEditableColumns[email].map(
          (lang) => {
            if (checkedItems.includes(lang.key)) {
              return { ...lang, checked: true };
            } else {
              return { ...lang, checked: false };
            }
          }
        );
        setUserEditableColumns(updateUserEditableColumns);
        setVisibleEditedColumnsModal(false);
      } catch (e) {
        message.error(e);
      }
    }
  };

  const handleCancel = () => {
    setVisibleEditedColumnsModal(false);
  };
  const [selectedUser, setSelectedUser] = useState(null);
  return (
    <Modal
      title="Tìm kiếm người dùng"
      visible={visibleManageUserModal}
      onCancel={onCancel}
      footer={null}
      onClose={handleCancel}
    >
      <div className="manage-user">
        <Input
          placeholder="Tìm kiếm người dùng"
          value={searchQuery}
          onChange={(e) => handleSearchChange(e.target.value)}
        />
        <List
          dataSource={searchQuery ? filteredUsers : users}
          renderItem={(user, index) => {
            const isEditor = user?.roles?.editor;
            const isUser = user?.roles?.user;

            return (
              <List.Item key={user.username}>
                <div style={{ width: "100%" }}>
                  <div className="item-user-list">
                    <p>Email: {user.username}</p>

                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <Checkbox
                        key={index}
                        checked={
                          userRoles[user.username]?.isAdmin ? true : false
                        }
                        onChange={() =>
                          handleAdminClick(
                            user.username,
                            userRoles[user.username]?.isAdmin
                          )
                        }
                      >
                        Admin
                      </Checkbox>

                      <Checkbox
                        key={index}
                        checked={
                          userRoles[user.username]?.isEditor ? true : false
                        }
                        onChange={() => {
                          setSelectedUser(user);
                          if (userRoles[user.username]?.isEditor) {
                            handleEditorClick(
                              user.username,
                              userRoles[user.username]?.isEditor
                            );
                          } else {
                            setVisibleEditedColumnsModal(true);
                          }
                        }}
                      >
                        Editor
                      </Checkbox>

                      <Checkbox
                        key={index}
                        checked={
                          userRoles[user.username]?.isUser ? true : false
                        }
                        onChange={() =>
                          handleUserClick(
                            user.username,
                            userRoles[user.username]?.isUser
                          )
                        }
                      >
                        User
                      </Checkbox>
                    </div>
                  </div>
                  <div>
                    {userRoles[user.username]?.isEditor && (
                      <>
                        <Checkbox
                          defaultChecked={user.allowedAddData ?? false}
                          onChange={(e) =>
                            handAllowAddData(user.username, e.target.checked)
                          }
                        >
                          Cho phép thêm từ
                        </Checkbox>
                        <Checkbox
                          defaultChecked={user.allowedDeleteData}
                          onChange={(e) =>
                            handAllowDeleteData(user.username, e.target.checked)
                          }
                        >
                          Cho phép xóa từ
                        </Checkbox>
                        {userEditableColumns[user.username]?.map(
                          (column, index) => (
                            <Checkbox
                              key={index}
                              defaultChecked={column.checked}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  column.id,
                                  user.username,
                                  column.key,
                                  e.target.checked
                                )
                              }
                            >
                              {column.label}
                            </Checkbox>
                          )
                        )}
                      </>
                    )}
                  </div>
                  <Modal
                    title="Các ngôn ngữ được phép chỉnh sửa:"
                    visible={visibleEditedColumnModal && selectedUser === user}
                    onOk={() =>
                      handleConfirmPromoteEditor(user.username, index)
                    }
                    onCancel={handleCancel}
                  >
                    {checkboxes?.map((column, index) => (
                      <Checkbox
                        key={index}
                        defaultChecked={false}
                        onChange={() =>
                          handleCheckboxPromoteEditor(column.id, column)
                        }
                      >
                        {column.label}
                      </Checkbox>
                    ))}
                  </Modal>
                </div>
              </List.Item>
            );
          }}
        />
      </div>
    </Modal>
  );
};

export default UserManagementModal;
