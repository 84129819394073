import React, { useEffect, useState } from "react";
import "./checkboxlist.scss";

const CheckboxList = React.memo(
  ({ checkboxes, changeColumn, setCheckboxes }) => {
    useEffect(() => {});
    const handleCheckboxChange = (id, column) => {
      const updatedCheckboxes = checkboxes.map((checkbox) => {
        if (checkbox.id === id) {
          return { ...checkbox, checked: !checkbox.checked };
        }

        return checkbox;
      });
      changeColumn(column);
      setCheckboxes(updatedCheckboxes);
    };
    return (
      <div className="checkboxes-container">
        {checkboxes &&
          checkboxes.map((checkbox, index) => (
            <div key={checkbox.id}>
              <label className="checkbox-container">
                <input
                  className="input_checkbox-dictionary"
                  type="checkbox"
                  checked={checkbox.checked}
                  onChange={() => handleCheckboxChange(checkbox.id, checkbox)}
                />
                {checkbox.label}
                <span className="checkmark"></span>
              </label>
            </div>
          ))}
      </div>
    );
  }
);

export default CheckboxList;
