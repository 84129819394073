import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import "./confirmation.scss";
const Confirmation = React.memo(
  ({
    addedData,
    checkBoxData,
    onClose,
    onConfirmAdd,
    isEdit,
    onConfirmEdit,
  }) => {
    return (
      <div className="confirmation-container">
        <Modal
          visible={true}
          onCancel={onClose}
          className="warning_modal"
          closable={false}
          footer={
            <div style={{ display: "flex", gap: "8px" }}>
              <button className="btn btn-cancel" onClick={onClose}>
                Hủy
              </button>
              <button
                className="btn btn-delete"
                style={{ backgroundColor: "#D9D9D9", color: "#C70F15" }}
                onClick={() =>
                  isEdit ? onConfirmEdit() : onConfirmAdd(addedData)
                }
              >
                Xác nhận
              </button>
            </div>
          }
          width={329}
          centered
        >
          {isEdit ? (
            <h4>Các thông tin bạn đã chỉnh sửa: </h4>
          ) : (
            <h4>Các thông tin bạn đã thêm: </h4>
          )}
          {Object.entries(addedData)
            .filter(([, value]) => value !== "")
            .map(([key, value]) => {
              const checkboxLabel = checkBoxData.find(
                (checkbox) => checkbox.key === key
              ).label;
              return (
                <p key={key} style={{ margin: "0" }}>
                  {checkboxLabel}: {value}
                </p>
              );
            })}
        </Modal>
      </div>
    );
  }
);

export default Confirmation;
