import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { database } from "../../firebase";
import { useNavigate } from "react-router-dom";
import "./auth.scss";
function ResetPassword() {
  const [email, setEmail] = useState("");
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [error, setError] = useState(null);
  const auth = getAuth();
  const history = useNavigate();
  const handleResetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setResetEmailSent(true);
        alert(
          "Reset email sent. Please check your email to reset your password."
        );
        history("/login");
        setError(null);
      })
      .catch((error) => {
        setResetEmailSent(false);
        setError("Bạn chưa nhập email!");
      });
  };

  return (
    <div className="reset-password-page">
      <div class="reset-password-container">
        <h2>Reset Password</h2>
        <p>Nhập địa chỉ email của bạn để đặt lại mật khẩu:</p>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={handleResetPassword}>Đặt lại mật khẩu</button>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </div>
  );
}

export default ResetPassword;
