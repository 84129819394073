import React, { useCallback, useState } from "react";
import { Button, Menu, Dropdown, Input } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import "../dictionary/dictionary.scss";

function ButtonAdmin({ setIsOpenUserManagement, setShowAddPopup }) {
  const menu = (
    <Menu>
      <Menu.Item key="English" onClick={() => setShowAddPopup(true)}>
        Thêm từ
      </Menu.Item>
      <Menu.Item key="Vietnamese" onClick={() => setIsOpenUserManagement(true)}>
        Quản lý User
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <div
        className="btn-admin-dictionary-dropdown"
        style={{ display: "flex", gap: "4px", height: "40px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            justifyContent: "center",
          }}
        >
          Admin
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
            width: "30px",
            gap: 0,
          }}
        >
          {" "}
          <FontAwesomeIcon icon={faGear} />
        </div>
      </div>
    </Dropdown>
  );
}

export default ButtonAdmin;
