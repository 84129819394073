import React, { useEffect, useState } from "react";
import SearchForm from "../components/dictionary/SearchForm";
import SearchResult from "../components/dictionary/SearchResult";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Confirmation from "../utils/components/Confirmation/Confirmation";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import EditModal from "../utils/components/Edit/edit";
import Warning from "../utils/components/Warning/warning";
import AddModal from "../utils/components/Add/add";
import "./layout.scss";
import { message } from "antd";
import { checkboxDataDefault } from "../utils/data";
import {
  confirmAddData,
  confirmEditData,
  deleteDictionaryRecord,
  searchDictionary,
} from "../utils/api/api.js";
import CheckboxList from "../utils/components/CheckBoxList";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useLogout from "../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import ButtonAdmin from "../components/admin/ButtonAdmin";
import useAuth from "../hooks/useAuth";
import UserManagementModal from "../components/admin/UserManagementModal";
const data = [];
const UserPage = () => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [addedData, setAddedData] = useState();
  const [isEdit, setIsEdit] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [visibleManageUserModal, setVisibleManageUserModal] = useState(false);
  const defaultColumns = [
    {
      title: "Anh",
      dataIndex: "English",
      key: "English",
      className: "occurences_column",
    },
    {
      title: "Số lần xuất hiện",
      dataIndex: "Occurrences",
      key: "Occurrences",
      className: "occurences_column",
    },
    {
      title: "Việt",
      dataIndex: "Vietnamese",
      key: "Vietnamese",
      className: "vietnamese_column",
    },
    {
      dataIndex: "action",
      className: "action-column",
      key: "Action",
      width: 100,
      render: (_, record) => (
        <div style={{ display: "flex", gap: "12px", justifyContent: "center" }}>
          <span
            className="btn-action-edit"
            onClick={(event) => {
              event.stopPropagation();
              setShowEditPopup(true);
              setSelectedRecord(record);
            }}
            style={{ cursor: "pointer" }}
          >
            <i style={{ width: "18px", height: "18px" }}>
              {" "}
              <FontAwesomeIcon icon={faPenToSquare} />
            </i>
          </span>{" "}
          {(auth.roles.includes("admin") || auth.allowedDeleteData) && (
            <span
              className="btn-action-delete"
              onClick={(event) => {
                event.stopPropagation();
                setShowWarningPopup(true);
                setSelectedRecord(record);
              }}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTrash} />{" "}
            </span>
          )}
        </div>
      ),
      align: "center",
    },
  ];
  const { auth } = useAuth();
  const verifiedColumn =
    auth.roles.length === 1 ? defaultColumns.slice(0, -1) : defaultColumns;
  const [columns, setColumns] = useState(verifiedColumn);
  const [dataDictionary, setDataDictionary] = useState(data);
  const [checkboxData, setCheckBoxData] = useState(checkboxDataDefault);
  const [fullEditedData, setFullEditedData] = useState();
  const [showTableDictionary, setShowTableDictionary] = useState(true);
  const axiosPrivate = useAxiosPrivate();

  const handleSearch = async (selectedKeyLng, seachValue, selectedLanguage) => {
    //call api tìm kiếm từ điển
    if (!seachValue) {
      return;
    }
    try {
      setIsLoading(true);
      setShowTableDictionary(false);
      const response = await searchDictionary(
        seachValue,
        selectedKeyLng,
        axiosPrivate
      );
      if (response.data.result.length === 0) {
        setIsLoading(false);
        setNotFound(true); // hiển thị khong tìm thấy
      } else {
        setIsLoading(false);
        setDataDictionary(response.data.result);
        setShowTableDictionary(true);
        changetoFirstColumn(selectedKeyLng, selectedLanguage); // dời cột ngôn ngữ cần hiển thị lên đầu bảng
        setNotFound(false);
      }
    } catch (e) {
      message.error("Error" + e);

      setIsLoading(false);
    }
  };
  const changetoFirstColumn = (selectedKeyLng, selectedLanguage) => {
    const indexOfCheckboxData = checkboxData.findIndex(
      //lấy vị trí của cột theo mảng checkbox
      (value) => value.key === selectedKeyLng
    );
    const indexOfSelectedColumn = columns.findIndex(
      (value) => value.key === selectedKeyLng
    ); //tìm kiếm cột cần hiển thị có sẵn trong bảng hay chưa
    if (indexOfSelectedColumn !== -1) {
      columns.splice(indexOfSelectedColumn, 1);
    } // nếu cột cần hiển thị có sẵn trong bảng thì loại cột đó để đem cột cần hiển lên cột đầu tiên
    setCheckBoxData((prevArray) => {
      const newArray = [...prevArray];
      newArray[indexOfCheckboxData].checked = true;
      return newArray;
    }); //tự động tick checkbox khi tìm kiếm từ điển
    setColumns([
      {
        title: selectedLanguage,
        dataIndex: selectedKeyLng,
        key: selectedKeyLng,
      },
      ...columns,
    ]); // dời cột cần hiển thị lên đầu tiên
  };
  function findFirstIndex(arr, condition) {
    // tim vi tri dau tien cua 1 trong 3 cot note, verse, occurrences
    for (let i = 0; i < arr.length; i++) {
      if (condition(arr[i])) {
        return i;
      }
    }
    return -1;
  }
  const handleEdit = async (data, editedData) => {
    setFullEditedData(data);
    setAddedData(editedData);
    setIsEdit(true);
    setShowEditPopup(false);
    setShowConfirmation(true);
  };
  const handleEditDataAfterConfirm = async () => {
    try {
      const response = await confirmEditData(
        fullEditedData.objectID,
        addedData,
        axiosPrivate
      );
      setDataDictionary(
        [...dataDictionary].map((obj) =>
          obj.objectID === response.data.result.objectID
            ? { ...obj, ...fullEditedData }
            : obj
        )
      );
      setShowConfirmation(false);
      setAddedData({});
      setFullEditedData("");
      setIsEdit(false);
      message.success(response.data.message);
    } catch (e) {
      message.error(e);
    }
  };
  const handleDelete = async (idRecord) => {
    try {
      const response = await deleteDictionaryRecord(idRecord, axiosPrivate);
      setDataDictionary(
        [...dataDictionary].filter((obj) => obj.objectID !== idRecord)
      );
      setShowWarningPopup(false);
      message.success(response.data.message);
    } catch (e) {
      message.error(e.response.data.message);
    }
  };
  const changeColumn = (column) => {
    //xu ly thay doi cot sau khi  tick, bỏ tick
    const newColumn = {
      title: column.label,
      dataIndex: column.key,
      key: column.key,
    };

    if (columns.some((value) => value.key === column.key)) {
      setColumns([...columns].filter((value) => value.key !== column.key)); // xu lý bỏ tick
    } else {
      //xử lý tick
      if (
        columns.some(
          (value) =>
            value.key === "Occurrences" ||
            value.key === "verse" ||
            value.key === "Action"
        ) // nếu đã có một trong ba cột này thì chèn các cột ngôn ngữ vào trước các cột này
      ) {
        if (column.key === "note") {
          //xử lý cột Ghi chú nằm ở cuối bảng{
          setColumns([
            ...[...columns].slice(0, columns.length - 1),
            newColumn,
            [...columns].pop(),
          ]);
        } else if (column.key === "Occurrences") {
          //Đặt cột SLXH kế cột cuối cùng
          if (columns.some((value) => value.key === "English")) {
            // Đặt cột SLXH sau cột English
            var firstIndex = findFirstIndex(columns, function (column) {
              return column.key === "English";
            });
            setColumns([
              ...[...columns].slice(0, firstIndex + 1),
              newColumn,
              ...[...columns].slice(firstIndex + 1, columns.length),
            ]);
          } else {
            if (columns.some((value) => value.key === "note")) {
              // kiểm tra xem đã có cột note hay chưa
              setColumns([
                ...[...columns].slice(0, columns.length - 2),
                newColumn,
                ...[...columns].slice(columns.length - 2, columns.length),
              ]);
            } else {
              setColumns([
                ...[...columns].slice(0, columns.length - 1),
                newColumn,
                [...columns].pop(),
              ]);
            }
          }
        } else {
          // chèn các cột ngôn ngữ đứng trước một trong các cột note,action, verse
          var firstIndex = findFirstIndex(columns, function (column) {
            return (
              column.key === "note" ||
              column.key === "Action" ||
              column.key === "verse"
            );
          });
          setColumns([
            ...[...columns].slice(0, firstIndex),
            newColumn,
            ...[...columns].slice(firstIndex, columns.length),
          ]);
        }
      } else {
        setColumns([...columns, newColumn]);
      }
    }
  };
  const handleAdd = async (data) => {
    setAddedData(data);
    setShowConfirmation(true);
    setShowAddPopup(false);
  };
  const handleAddDataAfterConfirm = async (data) => {
    try {
      const response = await confirmAddData(data, axiosPrivate);
      setShowConfirmation(false);

      message.success(response.data.message);
    } catch (e) {
      message.error(e.response.data.message);
    }
  };
  const navigate = useNavigate();
  const logout = useLogout();
  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };
  const onCancelManageUserModal = () => {
    setVisibleManageUserModal(false);
  };
  return (
    <div className="user-page-container">
      {visibleManageUserModal && (
        <UserManagementModal
          visibleManageUserModal={visibleManageUserModal}
          onCancel={onCancelManageUserModal}
        />
      )}
      <div style={{ position: "absolute", top: 33, right: 5 }}>
        <button
          onClick={handleLogout}
          style={{
            backgroundColor: "#ddd",
            color: "#c70f15",
            border: "none",
            borderRadius: "5px",
            padding: "10px",
            "font-size": "16px",
          }}
        >
          Đăng xuất
        </button>
      </div>

      <div style={{ textAlign: "center" }}>
        <h1 className="title-dictionary">TỪ ĐIỂN</h1>
      </div>
      <div className="sns-logo"></div>
      {showAddPopup && (
        <AddModal
          checkboxData={checkboxData}
          onClose={() => {
            setShowAddPopup(false);
            setAddedData({});
          }}
          onAdd={handleAdd}
          filledData={addedData}
        />
      )}
      {showConfirmation && (
        <Confirmation
          addedData={addedData}
          checkBoxData={checkboxData}
          onClose={() => {
            if (isEdit) {
              setShowConfirmation(false);
              setShowEditPopup(true);
            } else {
              setShowConfirmation(false);
              setShowAddPopup(true);
            }
          }}
          isEdit={isEdit}
          onConfirmAdd={handleAddDataAfterConfirm}
          onConfirmEdit={handleEditDataAfterConfirm}
        />
      )}
      {showWarningPopup && (
        <Warning
          message={"Bạn có chắc chắn muốn xóa từ này không ?"}
          onClose={() => setShowWarningPopup(false)}
          selectedRecord={selectedRecord}
          onDelete={handleDelete}
        />
      )}

      {showEditPopup && (
        <EditModal
          onClose={() => {
            setShowEditPopup(false);
            setAddedData({});
            setFullEditedData(null);
            setIsEdit(false);
          }}
          checkboxData={checkboxData}
          onSave={handleEdit}
          recordData={selectedRecord}
          fullEditedData={fullEditedData}
        />
      )}
      <div className="search-add-container">
        <SearchForm
          changeColumn={changeColumn}
          checkboxData={checkboxData}
          handleSearch={handleSearch}
          setCheckboxes={setCheckBoxData}
        />{" "}
        {auth.roles.includes("admin") && (
          <ButtonAdmin
            setShowAddPopup={setShowAddPopup}
            setIsOpenUserManagement={setVisibleManageUserModal}
          />
        )}
        {auth.allowedAddData && !auth.roles.includes("admin") && (
          <button
            className="btn-dictionary"
            onClick={() => setShowAddPopup(true)}
          >
            Thêm từ
          </button>
        )}
      </div>
      <div className="checkbox-container">
        <CheckboxList
          checkboxes={checkboxData}
          changeColumn={changeColumn}
          setCheckboxes={setCheckBoxData}
        />
      </div>

      <div className="result-search-container">
        <div className="table-col">
          {columns.length !== 1 && !notFound && showTableDictionary && (
            <SearchResult columns={columns} data={dataDictionary} />
          )}{" "}
          {}
        </div>{" "}
      </div>
      {notFound && <p>Không tìm thấy.</p>}
      {isLoading && (
        <img
          src={require("../assets/xoanocloading.gif")}
          style={{ width: "150px", height: "150px", marginTop: "50px" }}
        />
      )}
    </div>
  );
};
export default UserPage;
