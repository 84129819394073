import { Table } from "antd";
import "./dictionary.scss";
const SearchResult = ({ columns, data }) => {
  const resultArray = data || [];
  return (
    <div className="table-search-result">
      <Table
        dataSource={resultArray}
        columns={columns}
        panigation={false}
        bordered
      />
    </div>
  );
};

export default SearchResult;
