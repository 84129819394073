import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import axios from "../api/axios";
import { message } from "antd";
import "./login.scss";
export default function Login({ onLogin }) {
  const { setAuth } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    const data = {
      user: email,
      pwd: password,
    };
    try {
      setIsLoading(true);
      const response = await axios.post("/login", data, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      const allowedAddData = response?.data?.allowedAddData;
      const allowedColumns = response?.data?.allowedColumns;
      const allowedDeleteData = response?.data?.allowedDeleteData;
      setAuth({
        user: email,
        pwd: password,
        roles,
        accessToken,
        allowedAddData,
        allowedColumns,
        allowedDeleteData,
      });
      setPassword("");
      setIsLoading(false);
      history("/");
    } catch (error) {
      message.error(error.response.data.error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {isLoading && <div class="rotate-image"></div>}
        <div
          className={`bg-login-page ${isLoading && "icon_loading_container"} `}
        >
          <div
            style={{
              maxWidth: "500px",
              margin: "0 0 200px 0",

              textAlign: "center",
            }}
          >
            <h1 style={{ marginBottom: "30px" }}>Đăng nhập</h1>
            <form
              onSubmit={handleSubmit}
              className="form-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <input
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Email"
                className="form-field-login"
                required
              />
              <input
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                placeholder="Mật khẩu"
                className="form-field-login"
                required
              />
              <button
                type="submit"
                style={{
                  padding: "10px",
                  backgroundColor: "#ddd",
                  color: "#c70f15",
                  borderRadius: "5px",
                  border: "none",
                  marginBottom: "2px",
                }}
              >
                Đăng nhập
              </button>

              <button
                onClick={() => history("/register")}
                style={{
                  padding: "10px",
                  backgroundColor: "#ddd",
                  color: "#c70f15",
                  borderRadius: "5px",
                  border: "none",
                }}
              >
                Đăng ký{" "}
              </button>
            </form>
            <Link
              to={"/reset-password"}
              style={{ color: "#C70F15", textDecoration: "none" }}
              onClick={() => history("/reset-password")}
            >
              Quên mật khẩu ?{" "}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
