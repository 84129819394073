import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDO2qJBRACi_vVfsoyKOu-MFrZbC9jyGlc",
  authDomain: "dictionaries-hgn.firebaseapp.com",
  projectId: "dictionaries-hgn",
  storageBucket: "dictionaries-hgn.appspot.com",
  messagingSenderId: "37308692559",
  appId: "1:37308692559:web:ad62f7920381861dd6923a",
  measurementId: "G-M8D74W7G62",
};

const app = initializeApp(firebaseConfig);
export const database = getAuth();
