import React, { useCallback, useState } from "react";
import { Button, Menu, Dropdown, Input } from "antd";
import { DownOutlined } from "@ant-design/icons";
import "./dictionary.scss";
import CheckboxList from "../../utils/components/CheckBoxList";
const SearchForm = ({
  changeColumn,
  handleSearch,
  checkboxData,
  setCheckboxes,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedKeyLng, setSelectedKeyLng] = useState("English");
  const [selectedLng, setSelectedLng] = useState("Anh");

  const menu = (
    <Menu
      onClick={(e) => {
        setSelectedKeyLng(e.key);
        setSelectedLng(e.item.props.children);
      }}
    >
      <Menu.Item key="English">Anh</Menu.Item>
      <Menu.Item key="Vietnamese">Việt</Menu.Item>
      <Menu.Item key="Chinese">Trung</Menu.Item>
      <Menu.Item key="Hebrew">Hê-bơ-rơ</Menu.Item>
      <Menu.Item key="Greek">Hi Lạp</Menu.Item>
    </Menu>
  );
  const searchDictionary = (e) => {
    e.preventDefault();
    handleSearch(selectedKeyLng, searchValue, selectedLng);
  };
  return (
    <div className="search_container">
      <div style={{ display: "flex" }}>
        {" "}
        <Dropdown overlay={menu}>
          <div
            className="btn-dictionary-dropdown"
            style={{ display: "flex", gap: "4px" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                justifyContent: "center",
              }}
            >
              {selectedLng}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                justifyContent: "center",
                width: "30px",
              }}
            >
              {" "}
              <DownOutlined />
            </div>
          </div>
        </Dropdown>
        <form onSubmit={searchDictionary}>
          <div className="search-form-container">
            <input
              type="text"
              placeholder="Nhập từ cần tìm kiếm..."
              className="input-search-dictionary"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <button
              className="btn btn-search"
              style={{ height: "40px" }}
              onClick={searchDictionary}
            >
              Tra từ
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchForm;
