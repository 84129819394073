import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

function RegistrationSuccess() {
  const [countdown, setCountdown] = useState(3);
  const [redirect, setRedirect] = useState(false);

  // Sử dụng useEffect để bắt đầu đếm ngược
  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        setRedirect(true);
      }
    }, 1000);

    // Hủy bỏ timer khi component bị unmount
    return () => clearInterval(timer);
  }, [countdown]);

  // Nếu redirect === true, chuyển hướng về trang đăng nhập
  if (redirect) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <h2>Đăng ký thành công!</h2>
      <p>Chuyển hướng về trang đăng nhập sau {countdown} giây.</p>
    </div>
  );
}

export default RegistrationSuccess;
