import React, { useState } from "react";
import "./add.scss";
import { Modal, message } from "antd";
const AddModal = ({ onClose, onAdd, checkboxData, filledData }) => {
  const [formData, setFormData] = useState(
    filledData
      ? filledData
      : {
          English: "",
          Vietnamese: "",
          Chinese: "",
          Hebrew: "",
          Greek: "",
          note: "",
          verse: "",
          Occurrences: "",
        }
  );
  const handleSave = (e) => {
    e.preventDefault();
    if (
      !formData.English &&
      !formData.Vietnamese &&
      !formData.Chinese &&
      !formData.Greek &&
      !formData.Hebrew
    ) {
      message.warning("Vui lòng nhập ít nhất một ngôn ngữ!");
      return;
    } else {
      onAdd(formData);
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <Modal
      centered
      className="edit_modal"
      closable={false}
      visible={true} // Hiển thị modal
      onCancel={onClose}
      footer={[<></>]}
    >
      <form onSubmit={handleSave} style={{ marginTop: "16px" }}>
        {checkboxData
          .filter((checkbox) => checkbox.key !== "note")
          .map((checkbox, index) => (
            <div key={index} className="form-group">
              <label htmlFor="value1">{checkbox.label}</label>
              <input
                id="value1"
                autocomplete="off"
                type={checkbox.key === "Occurrences" ? "number" : "text"}
                name={checkbox.key}
                onChange={handleInputChange}
                value={formData[`${checkbox.key}`]}
              />
            </div>
          ))}

        <div className="form-group">
          <label htmlFor="value2">
            Ghi chú
            <strong> </strong>
          </label>
          <textarea id="value2" name="note" onChange={handleInputChange} />
        </div>
        <div style={{ display: "flex" }}>
          <button className="btn btn-cancel" key="cancel" onClick={onClose}>
            Hủy
          </button>
          <button type="submit" className="btn btn-save" key="save">
            Thêm
          </button>
        </div>
      </form>
    </Modal>
  );
};
export default AddModal;
