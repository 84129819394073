import { message } from "antd";
import { axiosPrivate } from "../../api/axios";

export const confirmPromoteAdmin = async (email, axiosPrivate) => {
  try {
    const response = await axiosPrivate.put(
      `/admin/change-admin-status/${email}`,
      { makeAdmin: true }
    );
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};
export const confirmRevokeAdmin = async (email, axiosPrivate) => {
  try {
    const response = await axiosPrivate.put(
      `/admin/change-admin-status/${email}`,
      { makeAdmin: false }
    );
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};
export const confirmPromoteEditor = async (
  email,
  allowedColumns,
  axiosPrivate
) => {
  try {
    const response = await axiosPrivate.put(
      `/admin/change-editor-status/${email}`,
      { makeEditor: true, allowedColumns }
    );
    message.success("Cập nhật thành công!");
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};
export const authorizeEditableColumns = async (
  email,
  allowedColumn,
  axiosPrivate,
  isRevoked
) => {
  try {
    const response = await axiosPrivate.put(
      `/admin/authorize-edited-column/${email}`,
      { allowedColumn: allowedColumn, revokeEditableColumns: isRevoked }
    );
    return response;
  } catch (e) {
    throw e;
  }
};
export const confirmRevokeEditor = async (email, axiosPrivate) => {
  try {
    const response = await axiosPrivate.put(
      `/admin/change-editor-status/${email}`,
      { makeEditor: false }
    );
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};
export const confirmAllowAddData = async (
  email,
  isRevokeAddData,
  axiosPrivate
) => {
  try {
    const response = await axiosPrivate.put(
      `/admin/authorize-add-data/${email}`,
      {
        isRevokeAddData: isRevokeAddData, // Fix the property name here
      }
    );
    return response;
  } catch (e) {
    throw new Error(e);
  }
};

export const confirmPromoteUser = async (email, axiosPrivate) => {
  try {
    const response = await axiosPrivate.put(
      `/admin/change-user-status/${email}`,
      { makeUser: true }
    );
    message.success("Cập nhật thành công!");
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};
export const confirmRevokeUser = async (email, axiosPrivate) => {
  try {
    const response = await axiosPrivate.put(
      `/admin/change-user-status/${email}`,
      { makeUser: false }
    );
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const confirmAllowDeleteData = async (
  email,
  isRevokeDeleteData,
  axiosPrivate
) => {
  try {
    const response = await axiosPrivate.put(
      `/admin/authorize-delete-data/${email}`,
      {
        isRevokeDeleteData: isRevokeDeleteData, // Fix the property name here
      }
    );
    return response;
  } catch (e) {
    throw new Error(e);
  }
};
